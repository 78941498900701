import { Box, Stack } from "@mui/material";
import { type ReactNode } from "react";

interface LegacyOneButtonDialogActionsProps {
  action: ReactNode;
}

/**
 * @deprecated
 * Legacy One Button Dialog Action to help transition to MUI standards while following
 * legacy Ion dialog layout
 */
export function LegacyOneButtonDialogActions(props: LegacyOneButtonDialogActionsProps) {
  const { action } = props;
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-around",
        borderTopWidth: "2px",
        borderColor: (theme) => theme.palette.grey[400],
        borderTopStyle: "solid",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          flex: 1,
          "&> *": {
            width: "100%",
          },
        }}
      >
        {action}
      </Box>
    </Stack>
  );
}
