import { mergeSxProps } from "@clipboard-health/ui-react";
import { FormControlLabel, type FormControlLabelProps, Radio } from "@mui/material";
import { type ReactElement } from "react";

interface RadioFieldProps<T> extends Omit<FormControlLabelProps, "control" | "componentsProps"> {
  value: T;
}

/**
 * A Radio + Label form field with the Radio control top aligned with the first line of text.
 * This is dependent on the `font-family`, along with typography values.
 */
export function RadioField<T>(props: RadioFieldProps<T>): ReactElement {
  const { sx, ...labelProps } = props;
  return (
    <FormControlLabel
      {...labelProps}
      sx={mergeSxProps(sx, {
        alignItems: "start",
      })}
      control={
        <Radio
          sx={{
            paddingTop: "9px",
          }}
        />
      }
      componentsProps={{
        typography: {
          sx: {
            /**
             * The Radio SVG is designed with 9px padding, to allow for touch target and overflow.
             * This margin is partly to incorporate that, but also is dependent on
             * the font-size and line-height, which is related to the font-family.
             * If we change `font-family`, then adjust this to align both single and multi-line
             * radio labels.
             */
            marginTop: "12px",
          },
        },
      }}
    />
  );
}
