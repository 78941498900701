import { isDefined } from "@clipboard-health/util-ts";
import { IonIcon } from "@ionic/react";
import { type InputBaseComponentProps, TextField } from "@mui/material";
import { call as callIcon } from "ionicons/icons";
import { type FunctionComponent, type ReactElement } from "react";
import { useController } from "react-hook-form";

import { StartInputAdornment } from "../../InputAdornments";
import { MaskedPhoneInput } from "./MaskedPhoneInput";

interface Props {
  name: string;
}

export function PhoneField(props: Props): ReactElement {
  const { name } = props;
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
  });

  return (
    <TextField
      {...field}
      fullWidth
      type="tel"
      label="Phone number"
      placeholder="e.g. (123) 456-7890"
      disabled={isSubmitting}
      error={isDefined(error?.message)}
      helperText={error?.message}
      InputProps={{
        startAdornment: (
          <StartInputAdornment>
            <IonIcon icon={callIcon} size="large" />
          </StartInputAdornment>
        ),
        inputComponent: MaskedPhoneInput as any as FunctionComponent<InputBaseComponentProps>,
      }}
    />
  );
}
