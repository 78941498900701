import { Box } from "@mui/material";
import { type ReactNode } from "react";

interface Props {
  children: ReactNode;
}
export function FullScreenPageLayout(props: Props) {
  const { children } = props;
  return (
    <Box
      sx={{
        height: "100vh",
        overflow: "auto",
        backgroundSize: "cover",
        background: "linear-gradient(168.65deg, #275ab9 0%, #33afd5 48.99%, #e3e6c4 99.02%)",
        /**
         * Padding to accommodate device camera notch
         * See https://fathomtech.io/blog/designing-react-web-applications-for-the-notch/
         */
        paddingTop: "env(safe-area-inset-top)",
        paddingBottom: "env(safe-area-inset-bottom)",
        paddingLeft: "env(safe-area-inset-left)",
        paddingRight: "env(safe-area-inset-right)",
      }}
    >
      {children}
    </Box>
  );
}
