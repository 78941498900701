import { isDefined } from "@clipboard-health/util-ts";
import { IonIcon } from "@ionic/react";
import { TextField } from "@mui/material";
import { mail as mailIcon } from "ionicons/icons";
import { type ReactElement } from "react";
import { useController } from "react-hook-form";

import { StartInputAdornment } from "../../InputAdornments";

interface Props {
  name: string;
}

export function EmailField(props: Props): ReactElement {
  const { name } = props;
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
  });

  return (
    <TextField
      {...field}
      fullWidth
      type="email"
      label="Email"
      placeholder="e.g. name@host.com"
      disabled={isSubmitting}
      error={isDefined(error?.message)}
      helperText={error?.message}
      InputProps={{
        startAdornment: (
          <StartInputAdornment>
            <IonIcon icon={mailIcon} size="large" />
          </StartInputAdornment>
        ),
      }}
    />
  );
}
