import { isDefined } from "@clipboard-health/util-ts";
import { TextField } from "@mui/material";
import { type ReactElement } from "react";
import { useController } from "react-hook-form";

interface Props {
  name: string;
}

export function OtpField(props: Props): ReactElement {
  const { name } = props;
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
  });

  return (
    <TextField
      {...field}
      fullWidth
      label="Verification Code"
      autoComplete="one-time-code"
      type="number"
      inputProps={{
        inputMode: "numeric",
      }}
      disabled={isSubmitting}
      error={isDefined(error?.message)}
      helperText={error?.message}
    />
  );
}
