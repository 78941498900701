import { type UseModalState } from "@clipboard-health/ui-react";
import { Dialog, type DialogProps } from "@mui/material";
import { type ReactNode } from "react";

import { APP_BAR_COLORS } from "../../Theme";

interface FullScreenDialogProps
  extends Omit<DialogProps, "open" | "onClose" | "fullScreen" | "PaperProps"> {
  modalState: UseModalState;
  children: ReactNode;
}

export function FullScreenDialog(props: FullScreenDialogProps) {
  const { modalState, children, ...dialogProps } = props;
  return (
    <Dialog
      fullScreen
      open={modalState.modalIsOpen}
      PaperProps={{
        sx: (theme) => ({
          backgroundColor:
            // TODO: Remove this once we migrate to new shift discovery theme
            theme.palette.customBackground?.statusBar ?? APP_BAR_COLORS.backgroundColors.light,
          paddingX: 0,
          paddingBottom: 0,
        }),
      }}
      {...dialogProps}
      onClose={() => {
        modalState.closeModal();
      }}
    >
      {children}
    </Dialog>
  );
}
