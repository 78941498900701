import { Box, Stack } from "@mui/material";
import { type ReactNode } from "react";

interface LegacyTwoButtonDialogActionsProps {
  leftAction: ReactNode;
  rightAction: ReactNode;
}

/**
 * @deprecated
 * Legacy Two Button Dialog Action to help transition to MUI standards while following
 * legacy Ion dialog layout
 */
export function LegacyTwoButtonDialogActions(props: LegacyTwoButtonDialogActionsProps) {
  const { leftAction, rightAction } = props;
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-around",
        "&> * + *": {
          borderLeftWidth: "2px",
          borderLeftStyle: "solid",
          borderColor: (theme) => theme.palette.grey[400],
        },
        borderTopWidth: "2px",
        borderColor: (theme) => theme.palette.grey[400],
        borderTopStyle: "solid",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          flex: 1,
          "&> *": {
            width: "100%",
          },
        }}
      >
        {leftAction}
      </Box>
      <Box
        sx={{
          flex: 1,
          textAlign: "center",
          "&> *": {
            width: "100%",
          },
        }}
      >
        {rightAction}
      </Box>
    </Stack>
  );
}
