import { type UseModalState } from "@clipboard-health/ui-react";
import { Dialog, DialogActions, DialogContent, type DialogProps, DialogTitle } from "@mui/material";
import { type ReactNode } from "react";

export interface CommonDialogProps extends Omit<DialogProps, "open" | "title"> {
  modalState: UseModalState;
  /**
   * title content is placed in a typography element, and therefore
   * must be valid for inline content
   */
  title: ReactNode;
  children: ReactNode;
  actions: ReactNode;
}
export function CommonDialog(props: CommonDialogProps) {
  const { modalState, title, children, actions, ...dialogProps } = props;
  return (
    <Dialog
      onClose={() => {
        modalState.closeModal();
      }}
      {...dialogProps}
      open={modalState.modalIsOpen}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions
        sx={{
          flexDirection: "column",
        }}
      >
        {actions}
      </DialogActions>
    </Dialog>
  );
}
