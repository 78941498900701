import { Text } from "@clipboard-health/ui-react";
import { Box, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { type ReactNode } from "react";

interface ProgressDialogProps {
  modalIsOpen: boolean;
  /**
   * Message content is placed in a typography element, and therefore
   * must be valid for inline content
   */
  messageContent: ReactNode;
}

export function ProgressDialog(props: ProgressDialogProps) {
  const { modalIsOpen, messageContent } = props;
  return (
    <Dialog open={modalIsOpen}>
      <DialogContent sx={{ textAlign: "center", marginX: 4, marginY: 3 }}>
        <Text variant="h1">{messageContent}</Text>
        <Box sx={{ marginTop: 4 }}>
          <CircularProgress />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
