import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

interface Props {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
/**
 * Adapted from
 * https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
 */
export const MaskedPhoneInput = forwardRef<HTMLElement, Props>((props, ref) => {
  const { onChange, name } = props;
  return (
    <IMaskInput
      {...props}
      overwrite
      mask="(#00) 000-0000"
      definitions={{
        "#": /[2-9]/,
      }}
      inputRef={ref}
      onAccept={(value: string) => {
        onChange({ target: { name, value } });
      }}
    />
  );
});

MaskedPhoneInput.displayName = "MaskedPhoneInput";
