import { InputAdornment } from "@mui/material";
import { type ReactNode } from "react";

interface Props {
  /**
   * Typically an icon from an icon package library, such as
   * @mui/icons-material, or ionic/icons
   * Example: `<IonIcon icon={ionicIconName} size="large" />`
   */
  children: ReactNode;
}

export function StartInputAdornment(props: Props) {
  const { children } = props;
  return (
    <InputAdornment position="start" sx={{ color: (theme) => theme.palette.grey[500] }}>
      {children}
    </InputAdornment>
  );
}
