import { type UseModalState } from "@clipboard-health/ui-react";
import { Dialog, DialogContent, type DialogProps, DialogTitle } from "@mui/material";
import { type ReactNode } from "react";

export interface NotificationDialogProps extends Omit<DialogProps, "open" | "onClose" | "title"> {
  title: ReactNode;
  modalState: UseModalState;
  children: ReactNode;
}
export function NotificationDialog(props: NotificationDialogProps) {
  const { title, modalState, children, ...dialogProps } = props;
  return (
    <Dialog
      {...dialogProps}
      open={modalState.modalIsOpen}
      onClose={() => {
        modalState.closeModal();
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
