import { IonPage } from "@ionic/react";
import { CircularProgress, Container } from "@mui/material";

import { CbhLogo } from "../../Content";
import { FULL_SCREEN_LAYOUT_TEXT_COLOR, FullScreenPageLayout } from "../FullScreen";

export function AppLoadingPage() {
  return (
    <IonPage>
      <FullScreenPageLayout>
        <Container
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: FULL_SCREEN_LAYOUT_TEXT_COLOR,
            gap: 2,
          }}
        >
          <CbhLogo />
          <CircularProgress color="inherit" />
        </Container>
      </FullScreenPageLayout>
    </IonPage>
  );
}
