import { DateCalendar, type DateCalendarProps } from "@mui/x-date-pickers";
import { useController } from "react-hook-form";

interface DateCalendarFieldProps extends DateCalendarProps<Date> {
  name: string;
}

export function DateCalendarField(props: DateCalendarFieldProps) {
  const { name, ...otherProps } = props;
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
  });
  return <DateCalendar {...otherProps} {...field} disabled={isSubmitting} />;
}
